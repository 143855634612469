<template>
  <a-drawer title="添加" width="50%" :visible="visible" :body-style="{ paddingBottom: '80px' }" @close="onClose">
    <a-spin :spinning="loading">
      <a-form :form="form" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="推荐位名称">
          <a-input
            type="number"
            v-decorator="[
              'position',
              {
                rules: [{ required: true, message: '请输入推荐位名称' }]
              }
            ]"
            placeholder="请输入推荐位名称"
          />
        </a-form-item>

        <!-- <a-form-item label="开始时间">
          <a-date-picker v-decorator="['beginTime', configBeginTime]" format="YYYY-MM-DD HH:mm:ss" :show-time="true" />
        </a-form-item>
        <a-form-item label="结束时间">
          <a-date-picker v-decorator="['endTime', configEndTime]" format="YYYY-MM-DD HH:mm:ss" :show-time="true" />
        </a-form-item> -->
        <!--        <a-form-item label="运费模板备注">-->
        <!--          <a-input v-decorator="['comment']" placeholder="请输入运费模板备注" />-->
        <!--        </a-form-item>-->
      </a-form>
      <div style="display: flex; justify-content: center; width: 100%; padding: 20px 16px">
        <a-button :style="{ marginRight: '8px' }" @click="onClose"> 关闭 </a-button>
        <a-button type="primary" @click="submit"> 确定 </a-button>
      </div>
    </a-spin>
  </a-drawer>
</template>

<script>
import {
  addRecommendPosition,
  editRecommendPosition,
  recommendPositionDetail
} from '@/api/modular/mallLiving/videoClass'
import moment from 'moment'

export default {
  name: 'custom-drawer',
  props: {
    visible: Boolean,
    default: false
  },
  data() {
    return {
      configBeginTime: {
        rules: [{ type: 'object', required: true, message: '请选择开始时间!' }]
      },
      configEndTime: {
        rules: [{ type: 'object', required: true, message: '请选择结束时间!' }]
      },
      form: this.$form.createForm(this),
      loading: false,
      detail: null
    }
  },

  methods: {
    onClose() {
      this.detail = null
      this.form.resetFields()
      this.$emit('update:visible', false)
    },
    submit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.info('success', values)
          const { position, beginTime, endTime } = values
          const params = {
            position
            // beginTime: beginTime.format('YYYY-MM-DD HH:mm:ss'),
            // endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
          }

          if (!this.detail) {
            this.add(params)
          } else {
            this.update({ ...params, id: this.detail.id })
          }
        }
      })
    },
    add(params) {
      addRecommendPosition(params)
        .then(res => {
          if (res.success) {
            this.$message.success('添加视频推荐位成功')
            this.onClose()
            setTimeout(() => {
              this.$parent.initTableData()
            }, 10)
          }
        })
        .catch(error => {
          this.$message.error(error.message || '添加视频推荐位失败')
        })
    },
    update(params) {
      editRecommendPosition(params)
        .then(res => {
          if (res.success) {
            this.$message.success('编辑视频推荐位成功')
            this.onClose()
            setTimeout(() => {
              this.$parent.initTableData()
            }, 10)
          }
        })
        .catch(error => {
          this.$message.error(error.message || '编辑视频推荐位失败')
        })
    },

    getDetail(id) {
      this.loading = true
      recommendPositionDetail({ id })
        .then(res => {
          console.log('詳情', res)
          if (res.success) {
            const data = res.data

            this.form.setFieldsValue({
              position: data.position
            })
            this.form.setFieldsValue({
              beginTime: moment(data.beginTime, 'YYYY-MM-DD HH:mm:ss')
            })
            this.form.setFieldsValue({
              endTime: moment(data.endTime, 'YYYY-MM-DD HH:mm:ss')
            })
            this.detail = data
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 20)
        })
    }
  }
}
</script>

<style scoped>
/deep/ .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
/deep/ .ant-checkbox-group-item {
  margin: 0 0 10px;
  width: 25%;
}
</style>
